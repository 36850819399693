import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import generateColors from '@/utils/color';
export default {
  components: {
    ElConfigProvider
  },
  setup() {
    const localColor = localStorage.getItem('color') || 'rgb(19,96,244)';
    const changePrimaryColor = value => {
      const domStyle = document.documentElement.style;
      let colorMap = generateColors(value);
      domStyle.setProperty('--el-color-primary', value);
      //生成不同1-9的透明度
      Object.keys(colorMap).forEach(key => {
        document.documentElement.style.setProperty(`--el-color-primary-${key}`, colorMap[key]);
      });
      localStorage.setItem('color', value);
    };
    changePrimaryColor(localColor);
    return {
      zhCn
    };
  }
};